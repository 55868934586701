import {ApproveCard} from "../../components/ApproveCard";
import {
    Table,
    TableHeader,
    TableColumn,
    TableBody,
    TableRow,
    TableCell,
    Spinner,
    getKeyValue, Select, SelectItem, Input, Button,
} from "@nextui-org/react";
import {useInfiniteScroll} from "@nextui-org/use-infinite-scroll";
import {useAsyncList} from "@react-stately/data";
import {useEffect, useState} from "react";
import {CreateApprovalModal} from "../../components/CreateApprovalModal";
import {BaseURL} from "../../variables/Variables";
import axios from "axios";
import {ApproveDetailsModal} from "../../components/ApproveDetailsModal";
import ExcelSave from "../../components/ExcelSave";

export const HomePage = () => {
    const [clickedItem, setClickedItem] = useState({id: null, open:false, name: ""});
    const [isLoading, setIsLoading] = useState(true);
    const [hasMore, setHasMore] = useState(false);
    const [newApprovals, setNewApprovals] = useState([]);
    const [listUpdated, setListUpdated] = useState(false);
    const [approvalType, setApprovalType] = useState([
        {id: "Race Base", name: "Race Base"}
    ])
    const [search, setSearch] = useState({
    })

    let url = `${BaseURL}/users/approve/list/`
    let downloadurl = `${BaseURL}/users/download/excel/all/`

    const [enableSearch, setEnableSearch] = useState(false);

    let list = useAsyncList({
        async load({signal, cursor}) {
            console.log(url);

            if (!cursor) {
                setIsLoading(false);
            }

            const res = await axios.get(cursor || url, {signal});

            let json = res.data

            setHasMore(json.next !== null);

            return {
                items: json.results,
                cursor: json.next,
            };
        },
    });

    const searchByFilter = () => {
        let params = new URLSearchParams(search).toString();
        url = `${BaseURL}/users/approve/list/?${params}`
        downloadurl = `${BaseURL}/users/download/excel/all/?${params}`
        list.reload();
    }

    useEffect(() => {
        axios.get(`${BaseURL}/users/approve/new/list/`).then(
            res => {
                setNewApprovals(res.data);
            }
        ).catch(
            err => {
                console.log(err)
            }
        )
    }, [listUpdated]);

    const [loaderRef, scrollerRef] = useInfiniteScroll({hasMore, onLoadMore: list.loadMore});
    return(
        <div className={"page-container"}>
            <div className={'flex flex-col md:flex-row gap-[30px] mt-[5px] w-full justify-start items-start'}>
                {
                    newApprovals.length > 0 &&
                    <div className={"flex flex-col gap-[12px] w-full md:w-auto"}>
                        <h2 className={"text-white font-bold py-[4px]"}>Новые</h2>
                        <div className={"md:height-transformer w-full min-w-[324px] md:max-w-[324px] w-full flex flex-col gap-[12px] rounded-md"}>
                            {
                                newApprovals.map((item, index) => (
                                    <ApproveCard
                                        key={index}
                                        id={item.id}
                                        name={item.name}
                                        username={item.user.full_name}
                                        avatar={item.user.avatar}
                                        updated_at={item.updated_at}
                                        approve_category={item.approve_category}
                                        approveUI={true}
                                        setListUpdated={setListUpdated}
                                        status={item.status}
                                    />
                                ))
                            }
                        </div>
                    </div>
                }
                <div className={"flex flex-col gap-[12px] w-full"}>
                    <div className={"flex items-center gap-[16px]"}>
                        <h2 className={"text-white font-bold"}>Все обращения</h2>
                        <CreateApprovalModal list={list}/>
                    </div>
                    <div className={"flex flex-col md:flex-row gap-[16px] items-center"}>
                        <div className={"md:max-w-[240px] text-white w-full"}>
                            <Input
                                type={"text"}
                                size={"sm"}
                                radius="lg"
                                style={{color: "white"}}
                                className={"input-text-color"}
                                classNames={{
                                    label: "input-text-color",
                                    input: "text-white/90",
                                    innerWrapper: "input-text-color",
                                    inputWrapper: "input-main",
                                }}
                                value={search.name}
                                placeholder={"Поиск"}
                                onChange={(event) => {
                                    setSearch({...search, name: event.target.value});
                                }}
                            />
                        </div>
                        <Select
                            value={""}
                            onChange={e => {
                                if (e.target.value === "Все"){
                                    const temp_search = {...search}
                                    delete temp_search.status;
                                    setSearch(temp_search)
                                }else{
                                    setSearch({...search, status: e.target.value});
                                }
                            }}
                            color={"success"}
                            className="md:w-[200px] text-white"
                            style={{borderRadius: 16, background: "#2A2A2D", border:"1px solid white"}}
                        >
                            <SelectItem key={"Все"} value={"Все"}>Все</SelectItem>
                            <SelectItem key={"Утверждено"} value={"Утверждено"}>Утверждено</SelectItem>
                            <SelectItem key={"Возвращен"} value={"Возвращен"}>Возвращен</SelectItem>
                            <SelectItem key={"Ожидает"} value={"Ожидает"}>Ожидает</SelectItem>
                        </Select>
                        <div className={"md:max-w-[240px] text-white w-full"}>
                            <Input
                                type={"date"}
                                size={"sm"}
                                radius="lg"
                                style={{color: "white"}}
                                className={"input-text-color"}
                                classNames={{
                                    label: "input-text-color",
                                    input: "text-white/90",
                                    innerWrapper: "input-text-color",
                                    inputWrapper: "input-main",
                                }}
                                value={search.date_from}
                                placeholder={"Название филиала"}
                                onChange={(event) => {
                                    setSearch({...search, date_from: event.target.value});
                                }}
                            />
                        </div>
                        <div className={"md:max-w-[240px] text-white w-full"}>
                            <Input
                                type={"date"}
                                size={"sm"}
                                radius="lg"
                                style={{color: "white"}}
                                className={"input-text-color"}
                                classNames={{
                                    label: "input-text-color",
                                    input: "text-white/90",
                                    innerWrapper: "input-text-color",
                                    inputWrapper: "input-main",
                                }}
                                value={search.date_to}
                                placeholder={"Название филиала"}
                                onChange={(event) => {
                                    setSearch({...search, date_to: event.target.value});
                                }}
                            />
                        </div>
                        <div className={"md:max-w-[100px] text-white w-full"}>
                            <Button
                                fullWidth={true}
                                onPress={searchByFilter}
                                className={"bg-yellow-500"}
                            >
                                Найти
                            </Button>
                        </div>
                        <div className={"md:max-w-[240px] text-white w-full"}>
                            <ExcelSave
                                url={downloadurl} name={`Консоль_трат_${search.date_from && search.date_from}_${search.date_to && search.date_to}`}
                            />
                        </div>
                    </div>
                    <div className={"height-transformer w-full flex flex-col gap-[12px] rounded-md"}>
                        <Table
                            isVirtualized={true}
                            isHeaderSticky
                            aria-label="approve table"
                            baseRef={scrollerRef}
                            bottomContent={
                                hasMore ? (
                                    <div className="flex w-full justify-center">
                                        <Spinner ref={loaderRef} color="white" />
                                    </div>
                                ) : null
                            }
                            classNames={{
                                base: "max-h-full overflow-scroll",
                                wrapper: "bg-[#323232] border-[#6F6F6F] border-[1px] rounded-md"
                            }}
                        >
                            <TableHeader>
                                <TableColumn className={"text-white bg-[#6F6F6F]"} key="priority">Приоритет</TableColumn>
                                <TableColumn className={"text-white bg-[#6F6F6F]"} key="category_name">Название категории</TableColumn>
                                <TableColumn className={"text-white bg-[#6F6F6F]"} key="name">Название</TableColumn>
                                <TableColumn className={"text-white bg-[#6F6F6F]"} key="status">Статус</TableColumn>
                                <TableColumn className={"text-white bg-[#6F6F6F]"} key="user">Отправитель</TableColumn>
                                <TableColumn className={"text-white bg-[#6F6F6F]"} key="created_at">Дата создания</TableColumn>
                                <TableColumn className={"text-white bg-[#6F6F6F]"} key="updated_at">Дата последнего изменения</TableColumn>
                                <TableColumn className={"text-white bg-[#6F6F6F]"} key="last_user">Требует подтверждения от</TableColumn>
                            </TableHeader>
                            <TableBody
                                isLoading={isLoading}
                                items={list.items}
                                loadingContent={<Spinner color="white" />}
                            >
                                {(item) => (
                                    <TableRow className={"hover:bg-gray-500 cursor-pointer rounded-md"} key={item.id}
                                              onClick={() => {
                                                  setClickedItem({id:item.id, open: true, name: item.name});
                                              }}>
                                        {(columnKey) => <TableCell className={`text-white min-w-[100px]`}>{getKeyValue(item, columnKey)}</TableCell>}
                                    </TableRow>
                                )}
                            </TableBody>
                        </Table>
                        <ApproveDetailsModal disabled={true} id={clickedItem.id} open={clickedItem.open} external={true} name={clickedItem.name} setItem={setClickedItem} setListUpdated={setListUpdated}/>
                    </div>
                </div>
            </div>
        </div>
    )
}
