import {
    Navbar,
    NavbarBrand,
    NavbarMenuToggle,
    NavbarMenuItem,
    NavbarMenu,
    NavbarContent,
    NavbarItem,
    Link,
    Button, User
} from "@nextui-org/react";
import {useEffect, useState} from "react";
import Logo from "../assets/MainLogo.png";
import {MeAPI} from "../api/ApiCalls";
import {useNavigate} from "react-router";

export const Header = () => {
    const history = useNavigate();
    const [me, setMe] = useState({});

    const [isMenuOpen, setIsMenuOpen] = useState(false);

    const menuItems = [
    ];

    const Logout = () => {
        localStorage.clear();
        window.location.reload();
    }

    useEffect(() => {
        const getMe = async () => {
            const res = await MeAPI();
            if (res){
                setMe(res);
            }
            if (res.is_first){
                history("/change_password");
            }
        }

        getMe();
    }, [setMe]);

    return(
        <Navbar
            isBordered
            isMenuOpen={isMenuOpen}
            onMenuOpenChange={setIsMenuOpen}
            maxWidth={"full"}
            className={"bg-[#231F2099] border-b-[1px] border-b-[#6F6F6F]"}
        >
            <NavbarContent justify="center">
                <NavbarBrand>
                    <img draggable={false} src={Logo} className={"max-w-[70px]"}/>
                </NavbarBrand>
            </NavbarContent>

            <NavbarContent justify="end">
                <NavbarContent className="sm:hidden" justify="end">
                    <NavbarMenuToggle className={"text-white"} aria-label={isMenuOpen ? "Close menu" : "Open menu"} />
                </NavbarContent>
            </NavbarContent>

            <NavbarMenu>
                {menuItems.map((item, index) => (
                    <NavbarMenuItem key={`${item}-${index}`}>
                        <Link
                            className="w-full"
                            color={
                                index === 2 ? "warning" : index === menuItems.length - 1 ? "danger" : "foreground"
                            }
                            href="#"
                            size="lg"
                        >
                            {item}
                        </Link>
                    </NavbarMenuItem>
                ))}
                <NavbarItem onClick={Logout}>
                    <Button className={"bg-black text-[red] font-bold"} fullWidth={true} href="#" variant="flat" onPress={Logout}>
                        Выйти
                    </Button>
                </NavbarItem>
            </NavbarMenu>

            {
                Object.keys(me).length > 0 &&
                <NavbarContent className={"hidden md:flex items-center"} justify={"end"}>
                    <NavbarItem className={"mt-[4px]"}>
                        <User
                            name={`${me.full_name}`}
                            description={`${me.position}`}
                            avatarProps={{
                                src: `${me.avatar}`
                            }}
                            className={"text-white"}
                        />
                    </NavbarItem>
                    <NavbarItem>
                        <Button className={"bg-black text-[red] font-bold"} href="#" variant="flat" onPress={Logout}>
                            Выйти
                        </Button>
                    </NavbarItem>
                </NavbarContent>
            }

        </Navbar>
    )
}
