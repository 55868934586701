import {Button, Input, Spinner} from "@nextui-org/react";
import Logo from "../../assets/MainLogo.png";
import {useState} from "react";
import {LoginAPI} from "../../api/ApiCalls";
import axios from "axios";
import {BaseURL} from "../../variables/Variables";
import {useNavigate} from "react-router";

export const FirstPasswordChange = () => {
    const history = useNavigate();
    const [error, setError] = useState("");
    const [isLoading, setIsLoading] = useState(false);
    const [passwordShown, setPasswordShown] = useState(false);

    const [submitData, setSubmitData] = useState(
        {
            password_again: "",
            password: ""
        }
    )
    const submit = async (e) => {
        e.preventDefault();
        if (submitData.password_again !== submitData.password){
            return setError("Пароли не совпадают");
        }
        setIsLoading(true);
        axios.post(`${BaseURL}/users/change_password/`, {password: submitData.password}).then(
            res => {
                history("/");
                window.location.reload();
            }
        ).catch(
            err => {
                setError(err.response.data.message);
            }
        )
    }

    const handleChange = e => {
        setError("");
        setSubmitData({...submitData, [e.target.name]: e.target.value});
    }

    return(
        <div className={"w-full mt-[40px] md:mt-[200px]"}>
            <div className={"w-full h-full flex flex-col justify-center items-center p-[12px]"}>
                <form
                    className={"flex flex-col bg-[white] p-[10px] w-full max-w-[324px] gap-[16px] rounded-lg border-[#FCE345] border-[1px]"}
                    onSubmit={submit}>
                    {error && <p>{error}</p>}
                    <h1 className={"text-[18px] font-bold"}>Смените пароль перед первым входом</h1>
                    <Input
                        disabled={isLoading}
                        required={true}
                        radius={"sm"}
                        labelPlacement={"inside"}
                        value={submitData.password}
                        name={"password"}
                        type={passwordShown ? "text" : "password"}
                        placeholder={"Пароль"}
                        classNames={{
                            inputWrapper: [
                                "input-wrapper"
                            ],
                        }}
                        onChange={handleChange}/>
                    <Input
                        disabled={isLoading}
                        required={true}
                        radius={"sm"}
                        labelPlacement={"inside"}
                        value={submitData.password_again}
                        name={"password_again"}
                        type={passwordShown ? "text" : "password"}
                        placeholder={"Повторите пароль"}
                        classNames={{
                            inputWrapper: [
                                "input-wrapper"
                            ],
                        }}
                        onChange={handleChange}/>
                    <Button
                        onPress={() => {setPasswordShown(!passwordShown)}}
                        type={"button"}
                            className={"text-center\n" +
                                "  rounded-md\n" +
                                "  bg-[white]\n" +
                                "  text-[14px]\n" +
                                "  font-bold\n" +
                                "  text-[black]\n" +
                                "  border-[1px]\n" +
                                "  border-[#5E5E5E]\n" +
                                "  flex\n" +
                                "  justify-between"}
                            disabled={isLoading}>
                        <Spinner size={"sm"} color={"secondary"}/>
                        {passwordShown ? "Скрыть пароль" : "Показать пароль"}
                        <Spinner size={"sm"} color={"secondary"}/>
                    </Button>
                    <Button type={"submit"}
                            className={"button"}
                            disabled={isLoading}>
                        <Spinner size={"sm"} color={"secondary"}/>
                        Войти
                        <Spinner size={"sm"} color={isLoading ? "primary" : "secondary"}/>
                    </Button>
                </form>
            </div>
        </div>
    )
}
