import {
    Modal,
    ModalContent,
    ModalHeader,
    ModalBody,
    Button,
    useDisclosure, Input, listbox, Spinner,
} from "@nextui-org/react";
import {useEffect, useState} from "react";
import axios from "axios";
import {BaseURL} from "../variables/Variables";
import {useNavigate} from "react-router";

export const CreateApprovalModal = ({list}) => {
    const history = useNavigate();
    const [isLoading, setIsLoading] = useState(false);
    const {isOpen, onOpen, onOpenChange} = useDisclosure();
    const [stage, setStage] = useState(1);
    const [categories, setCategories] = useState([]);
    const [template, setTemplate] = useState([]);
    const [selectedCategory, setSelectedCategory] = useState(null);
    const [sendData, setSendData] = useState({
        name: ""
    })

    const submit = e => {
        e.preventDefault();

        const data = new FormData();

        data.append("name", sendData.name);
        data.append("template", JSON.stringify(template));
        data.append("template_id", selectedCategory);

        axios.post(`${BaseURL}/users/approve/create/`, data).then(
            res => {
                if (res.data.success){
                    setStage(3);
                    list.reload();
                }
            }
        ).catch(
            err => {console.log(err)}
        )
    }

    const denyCategory = () => {
        setSelectedCategory(null);
        setStage(1);
    }

    const setCategory = (selectedCat) => {
        setSelectedCategory(selectedCat);
        setIsLoading(true);
        axios.get(`${BaseURL}/users/approve/${selectedCat}/template/`).then(
            res => {
                setIsLoading(false);
                if(res.data.is_redirect){
                    history(`${res.data.link}`);
                }else{
                    setTemplate(res.data);
                    setStage(2);
                }
            }
        ).catch(
            err => {console.log(err)}
        )
    }

    useEffect(() => {
        axios.get(`${BaseURL}/users/approve/categories/`).then(
            res => {
                setCategories(res.data);
            }
        ).catch(
            err => {
                console.log(err.response.data);
            }
        )
    }, []);

    return (
        <>
            <Button onPress={onOpen} size={"sm"} className={"font-bold"}>Добавить</Button>
            <Modal
                onClose={() => {
                    setStage(1);
                    setSelectedCategory(null);
                }}
                isOpen={isOpen}
                onOpenChange={onOpenChange}
                placement="top-center"
            >
                <ModalContent>
                    {(onClose) => (
                        <>
                            <ModalHeader
                                className="flex flex-col gap-1">
                                {stage === 1 ? "Выберите тип обращения" : "Заполните форму"}</ModalHeader>
                            {
                                stage === 1 &&
                                    <ModalBody>
                                        <div className={"flex flex-wrap pb-[12px] gap-[12px]  justify-start items-start"}>
                                            {
                                                categories.map((category, index) => (
                                                    <button className={"w-[80px]"}
                                                            onClick={() => {
                                                                setCategory(category.id)
                                                            }}
                                                            key={index}>
                                                        <div className={"flex gap-[4px] flex-col"}>
                                                            <img src={category.icon} alt={category.icon}
                                                                 className={"aspect-square object-contain rounded-md"}/>
                                                            <p className={"text-[12px] font-bold"}>
                                                                {category.name}
                                                            </p>
                                                        </div>
                                                    </button>
                                                ))
                                            }
                                        </div>
                                    </ModalBody>
                            }
                            {
                                stage === 2 &&
                                <ModalBody>
                                    <form onSubmit={submit} className={"flex flex-col gap-[12px]"}>
                                        <Input
                                            label={"Название"}
                                            required={true}
                                            type={"text"}
                                            onChange={(e) => {
                                                setSendData({...sendData, name: e.target.value})}}
                                        />
                                        {
                                            template && template.map((input, index) => (
                                                <Input
                                                    label={input.label}
                                                    required={input.required}
                                                    key={input}
                                                    type={input.type}
                                                    onChange={(e) =>
                                                        input.value = e.target.value}
                                                />
                                            ))
                                        }
                                        <div className={"flex gap-[12px] pb-[12px]"}>
                                            <Button type={"reset"} onPress={denyCategory} fullWidth={true}>
                                                Назад
                                            </Button>
                                            <Button type={"submit"} className={"bg-[#FCE345]"} fullWidth={true}>
                                                <Spinner size={"sm"} color={"secondary"}/>
                                                Войти
                                                <Spinner size={"sm"} color={isLoading ? "primary" : "secondary"}/>
                                            </Button>
                                        </div>
                                    </form>
                                </ModalBody>
                            }
                            {
                                stage === 3 &&
                                <ModalBody>
                                    <p className={"text-center text-black font-bold"}>Ваша заявка принята</p>
                                    <div className={"flex gap-[12px] pb-[12px]"}>
                                        <Button type={"reset"} onPress={onClose} fullWidth={true}>
                                            Закрыть
                                        </Button>
                                    </div>
                                </ModalBody>
                            }
                        </>
                    )
                    }
                </ModalContent>
            </Modal>
        </>
    )
}
